const Samsung = {};

Samsung.scroll = {
  init: function(){

    $('a[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        let target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top - 80
          }, 500);
          return false;
        }
      }
    });
    var mobile = false;
    var top;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      mobile = true;
    }
    if(mobile){
      top = 70;
    }else{
      top = 600;
    }
		let items = $('.js-anchor');
		let displayHeight = window.innerHeight;

		$(document).on('scroll', function(event){
      let screenPos = $(window).scrollTop();

      if(screenPos > 100){
        $('.cons__item').addClass('show');
      }

      for(let i = 0; i < items.length; i++){
        if (items[i].offsetTop >= (screenPos - items[i].offsetHeight) && items[i].offsetTop <= (screenPos  + 80)) {
          let data = $(items[i]).attr('data-section');
          $("[data-scroll="+data+"]").addClass('active').siblings().removeClass('active');
          if(data == 2 && items[i].offsetTop < screenPos - top){
            $('.banks__item').addClass('show');
            $("[data-scroll='5']").addClass('active').siblings().removeClass('active');
            console.log(1);
          }
        }

      }
		});
  }
};

Samsung.sliders = {
	init: function(){
		$('.js-how-to').slick({
			speed: 1000,
      dots: true,
      fade: true
		});
    $('.js-how-to').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $("[data-buy=" + nextSlide + "]").addClass('active').siblings().removeClass('active');
      if(nextSlide == 3){
        $('.screen-4-1').addClass('on');
      }
      if(nextSlide == 4){
        $('.screen-5-1').addClass('on');
      }
    });
	},
}

Samsung.menu = {
  init: function(){
    $('.js-menu-btn').on('click', function(){
      let t = $(this);
      t.toggleClass('active');
      $('.js-menu').toggleClass('active');
    });
    $('.js-scroll').on('click', function(){
      $('.js-menu').removeClass('active');
      $('.js-menu-btn').removeClass('active');
    });
  }
}

Samsung.questions = {
  init: function(){
    $('.js-question').on('click', function(){
      let t = $(this);
      t.find('.js-answer').slideToggle();
      t.find('.js-arrow').toggleClass('active');
    });
  }
}

$(document).ready(function() {
    Samsung.scroll.init();
		Samsung.sliders.init();
    Samsung.questions.init();
});
